var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layoutContent"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('homeItem',[_c('div',{staticClass:"d-flex padding-5"},[_c('div',{staticClass:"wapHide"},[(
            _vm.getConfigVal(_vm.curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
          )?_c('img',{staticStyle:{"width":"320px","margin-right":"30px"},attrs:{"src":require("@/assets/img/news.png"),"alt":"news"}}):_vm._e()]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.newsDescription)}})])]),_c('div',{staticClass:"homeBottom"},[_c('div',{staticClass:"homeLeft"},[_c('homeItem',{attrs:{"item-title":"upcomingElections"}},[_vm._l((_vm.upcomingElectionsList),function(item,index){return _c('div',{key:index,staticClass:"upcomingElection"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/election.png"),"width":"14","alt":"election"}})]),_c('div',[_c('div',{staticClass:"electionTitle"},[_vm._v(" "+_vm._s(item.electionTitle)+" ")]),(item.electionStatus == 1)?_c('div',[_c('span',{staticClass:"featuredFont"},[_vm._v(_vm._s(item.electionStatusStr))])]):(item.electionStatus == 2)?_c('div',[_c('k-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.PW_Worker_Portal_Code.WorkAssignments.View),expression:"PW_Worker_Portal_Code.WorkAssignments.View"}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.viewAssignments()}}},[_vm._v(" viewAssignments ")])],1):_c('div',[(_vm.showApply)?_c('k-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.PW_Worker_Portal_Code.Home.Apply),expression:"PW_Worker_Portal_Code.Home.Apply"}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.apply(item)}}},[_vm._v(" apply ")]):_vm._e()],1)])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.upcomingElectionsList.length == 0),expression:"upcomingElectionsList.length == 0"}]},[(
              _vm.getConfigVal(
                _vm.curPageConfigAndDefaultPageConfig,
                'showPic',
                'vIf'
              )
            )?_c('div',{staticClass:"noDataImg",staticStyle:{"height":"160px"}},[_c('div',{staticClass:"noDataFont"},[_vm._v(" "+_vm._s(_vm.$g("noDataText"))+" ")])]):_vm._e(),_c('div',{staticStyle:{"margin-top":"50px"},domProps:{"innerHTML":_vm._s(_vm.upCommingElectionsNoData)}})])],2)],1),_c('div',{staticClass:"homeCenter"},[_c('homeItem',{attrs:{"item-title":"outstandingTraining"}},[_vm._l((_vm.outstandingTrainingList),function(item,index){return _c('div',{key:index,staticClass:"upcomingElection"},[_c('div',{staticClass:"outstandingTrainingDot"}),_c('k-link',{on:{"click":function($event){return _vm.upcomingElectionClick(item)}}},[_vm._v(" "+_vm._s(item.eleClassCode)+"-"+_vm._s(item.classTypeName)+" ")])],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.outstandingTrainingList.length > 0),expression:"outstandingTrainingList.length > 0"}],staticClass:"text-center",staticStyle:{"margin-top":"80px"}},[_c('k-button',{attrs:{"variant":"primary"},on:{"click":_vm.viewMoreInformation}},[_vm._v(" viewMoreInformation ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.outstandingTrainingList.length == 0),expression:"outstandingTrainingList.length == 0"}]},[(
              _vm.getConfigVal(
                _vm.curPageConfigAndDefaultPageConfig,
                'showPic',
                'vIf'
              )
            )?_c('div',{staticClass:"noDataImg",staticStyle:{"height":"160px"}},[_c('div',{staticClass:"noDataFont"},[_vm._v(" "+_vm._s(_vm.$g("noDataText"))+" ")])]):_vm._e(),_c('div',{staticStyle:{"margin-top":"50px"},domProps:{"innerHTML":_vm._s(_vm.outstandingTrainingNoData)}})])],2)],1),_c('div',{staticClass:"homeCenter"},[_c('homeItem',{attrs:{"item-title":"newWorkAssignments"}},[_vm._l((_vm.newWorkAssignmentsList),function(item,index){return _c('div',{key:index,staticClass:"upcomingWorkAssignments"},[_c('div',{staticClass:"dayJob featuredFont"},[_vm._v(" "+_vm._s(item.workDay)+" "),_c('span',{staticClass:"bold eleJobTitle ml-3"},[_vm._v(" "+_vm._s(item.eleJobTitle)+" ")])]),_vm._v(" "+_vm._s(_vm.$g("workLocation"))+": "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(item.elePollingPlaceName))])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newWorkAssignmentsList.length > 0),expression:"newWorkAssignmentsList.length > 0"}],staticClass:"text-center",staticStyle:{"margin-top":"80px"}},[_c('k-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.PW_Worker_Portal_Code.WorkAssignments.View),expression:"PW_Worker_Portal_Code.WorkAssignments.View"}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.viewAssignments()}}},[_vm._v(" respondToAssignments ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newWorkAssignmentsList.length == 0),expression:"newWorkAssignmentsList.length == 0"}]},[(
              _vm.getConfigVal(
                _vm.curPageConfigAndDefaultPageConfig,
                'showPic',
                'vIf'
              )
            )?_c('div',{staticClass:"noDataImg",staticStyle:{"height":"160px"}},[_c('div',{staticClass:"noDataFont"},[_vm._v(" "+_vm._s(_vm.$g("noDataText"))+" ")])]):_vm._e(),_c('div',{staticStyle:{"margin-top":"50px"},domProps:{"innerHTML":_vm._s(_vm.newWorkAssignmentsNoData)}})])],2)],1),_c('div',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.PW_Worker_Portal_Code.RequiredDocuments.RequiredDocuments),expression:"PW_Worker_Portal_Code.RequiredDocuments.RequiredDocuments"}],class:_vm.requiredDocumentsHomeRightClass},[_c('RequiredDocuments')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }