// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/newWork.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".upcomingElection[data-v-32084dce]{padding:15px 5px 15px;display:flex;border-bottom:1px dashed #e7eaee}.upcomingElection .electionTitle[data-v-32084dce]{margin-bottom:10px}.upcomingElection .b-icon.bi[data-v-32084dce]{cursor:auto}.upcomingElection img[data-v-32084dce]{margin-right:14px}.upcomingElection .outstandingTrainingDot[data-v-32084dce]{width:12px;height:12px;border:3px solid #e9ebec;border-radius:50%;align-self:center;margin-right:14px}.upcomingWorkAssignments[data-v-32084dce]{background:#fff;box-shadow:0 6px 20px 0 rgba(30,140,76,.15);border-radius:5px;padding:14px 24px;margin:10px 0}.homeCenter[data-v-32084dce],.homeLeft[data-v-32084dce]{flex:1;margin-right:6px}.homeRight[data-v-32084dce],.homeRight1[data-v-32084dce]{flex:1}.homeRight .fieldset[data-v-32084dce]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:100% 100%;background-size:140px}.homeBottom[data-v-32084dce]{display:flex;margin-top:10px}@media (max-width:1366px){.homeBottom[data-v-32084dce]{display:block}.homeBottom .homeCenter[data-v-32084dce],.homeBottom .homeLeft[data-v-32084dce]{margin-right:0;margin-bottom:10px}}", ""]);
// Exports
module.exports = exports;
